@media screen and (max-width: 768px) {
  [data-aos] {
    pointer-events: auto !important;
  }

  html:not(.no-js) [data-aos^="fade"][data-aos^="fade"] {
    opacity: 1 !important;
    transform: none !important;
  }
  html:not(.no-js) [data-aos^="zoom"][data-aos^="zoom"] {
    opacity: 1 !important;
    transform: none !important;
  }

  html:not(.no-js) [data-aos="fade-up"] {
    transform: none !important;
  }
}
.process-head {
  margin-left: 12rem !important;
}
.w-95 {
  width: 95%;
}
.mb-5rem {
  margin-bottom: 5rem;
}
.mr-5rem {
  margin-right: 5rem;
}
@media (min-width: 1100px) {
  .md-hidden {
    visibility: hidden;
    display: none !important;
  }
  .lg-p-3 {
    padding-left: 3rem !important;
  }
  .space-contact {
    margin-top: 20rem !important;
  }
}
@media (max-width: 1100px) {
  .mt-14 {
    margin-top: 0 !important;
  }
  .flex-mobile-tab {
    flex-direction: column-reverse;
  }
  .iframe-class {
    width: 600px !important;
    height: 550px !important;
  }
  .w-30vw {
    width: 100%;
  }
  .mt-10rem {
    margin-top: 15rem;
  }
  .mobile-hidden {
    display: none !important;
  }
  .mobile-justify-between {
    justify-content: space-between !important;
  }
  .mobile-justify-center {
    justify-content: center !important;
  }
  .m-w-100 {
    width: 100% !important;
  }
  .h-35rem {
    height: 65rem !important;
  }
  .m-mt-2rem {
    margin-top: 2rem;
  }
  .font-playfair-display {
    height: 100% !important;       
    width: 75vw !important;
  }
  .gordita-regular {
    height: 100% !important;
    width: 85vw !important;
  }
  .services_section {
    padding: 2rem 1rem !important;
  }
  .btn {
    padding: 10px 20px !important;
  }
  .m-mt-4rem {
    margin-top: 4rem !important;
    padding: 0rem 2rem;
  }
  .footerClass {
    padding: 2rem 4rem 0 1rem !important;
  }
  .reveal-from-bottom {
    margin: 5px !important;
    flex-basis: 30%;
  }
  .m-w-80 {
    width: 80% !important;
  }
  .mobile-flex {
    flex-direction: column !important;
  }
}

@media (max-width: 767px) {
  .h-44 {
    height: 35rem !important;
  }
  .w-33percent {
    width: 100% !important;
  }
  .iframe-class {
    width: 350px !important;
    height: 350px !important;
  }
  .contact-head-space {
    margin-top: 48vh !important;
  }
  .map-placed {
    margin: 0 !important;
  }
  .mt-14 {
    margin-top: 4rem !important;
  }
  .say-backG {
    margin: 340px 0 0 !important;
  }
  .accessibilty-svg {
    margin-top: -4rem;
    margin-bottom: 2rem;
    width: 10rem !important;
  }
  .mobile-flex-wrap {
    flex-wrap: wrap;
  }
  .h2 {
    font-size: 50px !important;
  }
  .font-playfair-display {
    font-size: 50px !important;
  }
  .affordable {
    font-size: 50px !important;
  }
  .h-35rem {
    height: 60rem !important;
  }

  /* our services */
  .mob-service {
    display: block !important;
  }
  .w-50 {
    width: 100% !important;
  }
  .cta-class {
    margin-bottom: 2rem;
    /* margin-left: 35%; */
  }
  .m-f-14 {
    font-size: 14px !important;
  }
  .rect {
    width: 20px !important;
    height: 10px !important;
  }
  .rect-2 {
    bottom: 0.5rem !important;
    left: -1.1rem !important;
  }
  .rect-3 {
    bottom: 0.5rem !important;
    left: -1.1rem !important;
    width: 9px !important;
  }

  /* Bit */
  .mob-bit {
    display: block !important;
  }
  .margin-advance {
    margin-top: 6rem !important;
  }
  .bit-h3 {
    margin-top: -28rem !important;
  }
  .bit-p {
    margin-top: 24rem;
  }
  /* .bit-a{
        margin-left: 5rem;
    } */
  .bit-img {
    margin-top: 5rem;
  }

  /* dentist team  */
  .mob-team {
    margin-bottom: 3rem;
    text-align: center;
  }

  /* proccess */
  .mob-process {
    display: block !important;
  }
  .process-head {
    width: 100% !important;
    margin-left: 0rem !important;
  }
  .process-heading {
    font-size: 45px !important;
    margin-top: -3rem !important;
  }
  .p-Icon {
    margin-top: -9rem !important;
  }
  .p2-img {
    padding-bottom: 5rem;
  }
  .process-cta-img {
    margin-top: 3rem !important;
    margin-bottom: -1rem !important;
    margin-left: 4% !important;
  }
  .process-cont {
    margin-bottom: 3rem;
  }
}

@media (min-width: 768px) and (max-width: 1366px) {
  .h-44 {
    height: 34rem;
  }
  .accessibilty-svg {
    margin-top: 6rem;
  }
  .service-i-img {
    margin-top: 17rem;
  }
  .w-200 {
    width: 200%;
  }
  .list-ipad {
    margin-top: 4rem;
    margin-left: 2rem;
  }

  /* bit about  */

  .bit-img {
    margin-top: 11rem;
  }

  /* dental team  */
  .process-head {
    margin-left: 5rem !important;
    margin-top: -3rem !important;
  }

  .p1-img {
    height: 25rem !important;
  }

  .process-cta-img {
    margin-top: 5rem;
  }
  .footerClass {
    padding: 4rem 6rem 0 6rem !important;
  }
}
@media (min-width: 1536px) {
  .services_section {
    padding: 4rem 14rem 4rem 15rem !important;
  }
  .footerClass {
    padding: 4rem 10rem 0 10rem !important;
  }
}
@media (min-width: 2100px) {
  .h-44 {
    height: 55rem !important;
  }
  .mac-justify-between {
    justify-content: space-between !important;
  }
  .iframe-class {
    width: 800px !important;
    height: 750px !important;
  }
  .w-30vw {
    width: 24rem;
  }
  .h-35rem {
    height: 45rem !important;
  }
  .max-w-8xl {
    max-width: 96rem !important;
  }
  /* .container {
      max-width: 2100px !important;
    } */
  .mac-mr-1rem {
    margin-right: 1rem;
  }
  .process-head {
    margin-left: 22.3rem !important;
  }
  .p1-img {
    margin-left: -15rem !important;
  }
  .p2-img {
    margin-right: -11rem;
  }
  .services_section {
    padding: 4rem 8rem 4rem 7rem !important;
  }
  .footerClass {
    padding: 5rem 22rem 0 25rem !important;
  }
}

