
h1,
h2,
h3,
h5 {
  font-family: TiemposHeadline Medium !important;
}

h4 {
  font-family: Gordita Regular;
}

p {
  font-family: Gordita Regular;
  color: #565e81 !important;
}

.h-44 {
  height: 44rem;
}
.mt-14 {
  margin-top: 14rem;
}

.testimonial-item-name {
  font-family: Gordita Regular;
  color: #2b2b52 !important;
}

.services_section {
  padding: 4rem 4rem 4rem 5rem;
}
.mt-3rem {
  margin-top: 3rem !important;
}

.btn-enroll {
  padding: 10px 40px !important;
}

.font-14 {
  font-size: 14px !important;
}
.headings-2 {
  margin-bottom: 1rem;
  font-size: 24px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
}

.imageClass {
  border-radius: 12px;
}

.teamImgClass {
  border-radius: 12px 12px 0 0;
  cursor: pointer;
}
.w-20rem {
  width: 20rem;
}
.fontSize-14 {
  font-size: 14px;
}

.say_container {
  padding: 0 0 6rem 0;
}

.other-header-display {
  align-items: center;
  justify-items: center;
  height: 240px;
  flex-grow: 0;
  font-family: TiemposHeadline Medium !important;
  font-size: 60px;
  margin-top: 2rem;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: -1px;
  color: #191919;
}

.say-backG {
  /* background-image: url('/src/images/say-backG.svg'); */
  background-image: linear-gradient(
    to top,
    #f0f4fd,
    rgba(240, 244, 253, 0) 28%
  );
  width: 100vw;
  height: 780px;
  position: absolute;
  margin: -272.7px 0 0;
  z-index: -5;
  /* position: absolute;
    height: 28.5rem;
    z-index: -5; */
}

.process-icons {
  width: 4rem !important;
}

.say-footer-svg {
  width: 17.6px;
  height: 13.9px;
  margin: 27px 12.4px 2.1px 0;
  padding: 0 9.2px 0 0;
  background-color: #d9e0f0;
}

.card_corner_image {
  border-radius: 50%;
  position: relative;
  bottom: 1.5rem;
}

.card-p {
  font-size: 1.1rem;
  line-height: 1.7rem;
  color: gray;
}

.process_container {
  background-color: #82ddfc;
}
.gortida {
  font-family: Gordita Regular;
}

.footerClass {
  padding: 4rem 14rem 0 14rem;
}
.cta-class {
  width: 7rem;
}
.w-30vw {
  width: 24rem;
}
.map-placed {
  z-index: -1;
  margin-left: -2rem;
}

.footer-heading {
  color: white;
  /* font-weight: 800; */
  font-size: 1rem;
  margin-bottom: 1rem;
}

.footer-p {
  color: rgba(230, 242, 255, 0.6);
  font-size: 14px !important;
  line-height: 1.7rem;
  font-family: Gordita Regular;
}

.background-img {
  left: 5rem;
  top: 11rem;
  position: relative;
  height: 8rem;
  background-color: #097cf5;
  transform: rotate(40deg);
  z-index: -2;
}

.img-margin {
  margin-top: -8rem;
}

.background-img-advance {
  width: 10rem;
  left: 8rem;
  top: 26.5rem;
  position: relative;
  height: 8rem;
  background-color: #097cf5;
  transform: rotate(28deg);
  z-index: -2;
}

.margin-advance {
  margin-right: 5rem;
  margin-top: -5rem;
}

.process_container {
  background-color: #f3fcff !important;
  /* opacity: 0.1; */
}
.p-Icon {
  width: 80px;
  height: 80px;
  flex-grow: 0;
  object-fit: contain;
}
.p1-img {
  position: absolute;
}
.p2-img {
  position: relative;
  float: right;
}
.process-heading {
  width: 768px;
  height: 144px;
  flex-grow: 0;
  font-size: 56px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: -0.5px;
  text-align: center;
  color: #002855;
}
.rect {
  width: 17px;
  height: 15px;
  flex-grow: 0;
  margin: 1px 15px 0 0;
  background-color: #2174ea;
}
.rect-2 {
  bottom: 0.5rem;
  position: relative;
  width: 16px;
  height: 5px;
  flex-grow: 0;
  background-color: #bfdbfe;
  left: -1.6rem;
}
.rect-3 {
  bottom: 0.5rem;
  left: -1.6rem;
  position: relative;
  width: 6px;
  height: 17px;
  flex-grow: 0;
  background-color: #bfdbfe;
}

.accessibilty-bg {
  color: white;
  background-image: url("/src/images/access-state-bg.png");
}
.contact-bg {
  color: white;
  background-color: #0181f6;
  background-image: url("/src/images/BG\ contact.svg");
}
.iframe-class {
  width: 800px;
  height: 600px;
}

.accessibilty-svg {
  width: 15rem;
}
.mt-10rem {
  margin-top: 10rem;
}
.mt-2rem {
  margin-top: 2rem;
}
ul.disc{
  list-style: disc;
  padding-left:40px;
}
.services-text p{
  margin-bottom: 10px;
}
.banner-section img{
  border-radius: 30px;
}