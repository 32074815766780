@import "./mobilecss/mobile-responsive.css";
@import "./personalCSS/services.css";
@import url("https://fonts.googleapis.com/css2?family=Red+Hat+Display:wght@500;700;900&family=Inter:wght@400;500;700&display=fallback");
*,
::before,
::after {
  box-sizing: border-box; /* 1 */
  border-width: 0; /* 2 */
  border-style: solid; /* 2 */
  border-color: #e4e4e7; /* 2 */
}
::before,
::after {
  --tw-content: "";
}

@font-face {
  font-family: "Gordita Regular";
  font-style: normal;
  font-weight: normal;
  src: url("../fonts/Gordita\ Regular.woff") format("woff");
}
@font-face {
  font-family: "TiemposHeadline Medium";
  src: url("../fonts/TiemposHeadline-Medium.otf") format("opentype");
}

html {
  line-height: 1.5; /* 1 */
  -webkit-text-size-adjust: 100%; /* 2 */
  -moz-tab-size: 4; /* 3 */
  -o-tab-size: 4;
  tab-size: 4; /* 3 */
  /* font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"; 4 */
  font-family: Gordita Regular !important;
}

body {
  margin: 0; /* 1 */
  line-height: inherit; /* 2 */
}

hr {
  height: 0; /* 1 */
  color: inherit; /* 2 */
  border-top-width: 1px; /* 3 */
}

abbr:where([title]) {
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  text-decoration: inherit;
}

b,
strong {
  font-weight: bolder;
}

code,
kbd,
samp,
pre {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas,
    "Liberation Mono", "Courier New", monospace; /* 1 */
  font-size: 1em; /* 2 */
}
/*
Add the correct font size in all browsers.
*/
small {
  font-size: 80%;
}
/*
Prevent `sub` and `sup` elements from affecting the line height in all browsers.
*/
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}
sub {
  bottom: -0.25em;
}
sup {
  top: -0.5em;
}
/*
1. Remove text indentation from table contents in Chrome and Safari. (https://bugs.chromium.org/p/chromium/issues/detail?id=999088, https://bugs.webkit.org/show_bug.cgi?id=201297)
2. Correct table border color inheritance in all Chrome and Safari. (https://bugs.chromium.org/p/chromium/issues/detail?id=935729, https://bugs.webkit.org/show_bug.cgi?id=195016)
3. Remove gaps between table borders by default.
*/
table {
  text-indent: 0; /* 1 */
  border-color: inherit; /* 2 */
  border-collapse: collapse; /* 3 */
}

button,
input,
optgroup,
select,
textarea {
  font-family: inherit; /* 1 */
  font-size: 100%; /* 1 */
  line-height: inherit; /* 1 */
  color: inherit; /* 1 */
  margin: 0; /* 2 */
  padding: 0; /* 3 */
}
/*
Remove the inheritance of text transform in Edge and Firefox.
*/
button,
select {
  text-transform: none;
}
/*
1. Correct the inability to style clickable types in iOS and Safari.
2. Remove default button styles.
*/
button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; /* 1 */
  background-color: transparent; /* 2 */
  background-image: none; /* 2 */
}
/*
Use the modern Firefox focus style for all focusable elements.
*/
:-moz-focusring {
  outline: auto;
}
/*
Remove the additional `:invalid` styles in Firefox. (https://github.com/mozilla/gecko-dev/blob/2f9eacd9d3d995c937b4251a5557d95d494c9be1/layout/style/res/forms.css#L728-L737)
*/
:-moz-ui-invalid {
  box-shadow: none;
}
/*
Add the correct vertical alignment in Chrome and Firefox.
*/
progress {
  vertical-align: baseline;
}
/*
Correct the cursor style of increment and decrement buttons in Safari.
*/
::-webkit-inner-spin-button,
::-webkit-outer-spin-button {
  height: auto;
}
/*
1. Correct the odd appearance in Chrome and Safari.
2. Correct the outline style in Safari.
*/
[type="search"] {
  -webkit-appearance: textfield; /* 1 */
  outline-offset: -2px; /* 2 */
}
/*
Remove the inner padding in Chrome and Safari on macOS.
*/
::-webkit-search-decoration {
  -webkit-appearance: none;
}
/*
1. Correct the inability to style clickable types in iOS and Safari.
2. Change font properties to `inherit` in Safari.
*/
::-webkit-file-upload-button {
  -webkit-appearance: button; /* 1 */
  font: inherit; /* 2 */
}
/*
Add the correct display in Chrome and Safari.
*/
summary {
  display: list-item;
}
/*
Removes the default spacing and border for appropriate elements.
*/
blockquote,
dl,
dd,
h1,
h2,
h3,
h4,
h5,
h6,
hr,
figure,
p,
pre {
  margin: 0;
}
fieldset {
  margin: 0;
  padding: 0;
}
legend {
  padding: 0;
}
ol,
ul,
menu {
  list-style: none;
  margin: 0;
  padding: 0;
}
/*
Prevent resizing textareas horizontally by default.
*/
textarea {
  resize: vertical;
}
/*
1. Reset the default placeholder opacity in Firefox. (https://github.com/tailwindlabs/tailwindcss/issues/3300)
2. Set the default placeholder color to the user's configured gray 400 color.
*/
input::-moz-placeholder,
textarea::-moz-placeholder {
  opacity: 1; /* 1 */
  color: #a2a2a8; /* 2 */
}
input:-ms-input-placeholder,
textarea:-ms-input-placeholder {
  opacity: 1; /* 1 */
  color: #a2a2a8; /* 2 */
}
input::placeholder,
textarea::placeholder {
  opacity: 1; /* 1 */
  color: #a2a2a8; /* 2 */
}
/*
Set the default cursor for buttons.
*/
button,
[role="button"] {
  cursor: pointer;
}
/*
Make sure disabled buttons don't get the pointer cursor.
*/
:disabled {
  cursor: default;
}
/*
1. Make replaced elements `display: block` by default. (https://github.com/mozdevs/cssremedy/issues/14)
2. Add `vertical-align: middle` to align replaced elements more sensibly by default. (https://github.com/jensimmons/cssremedy/issues/14#issuecomment-634934210)
   This can trigger a poorly considered lint error in some tools but is included by design.
*/
img,
svg,
video,
canvas,
audio,
iframe,
embed,
object {
  display: block; /* 1 */
}
/*
Constrain images and videos to the parent width and preserve their intrinsic aspect ratio. (https://github.com/mozdevs/cssremedy/issues/14)
*/
img,
video {
  max-width: 100%;
  height: auto;
}
/*
Ensure the default browser behavior of the `hidden` attribute.
*/
[hidden] {
  display: none;
}
[type="text"],
[type="email"],
[type="url"],
[type="password"],
[type="number"],
[type="date"],
[type="datetime-local"],
[type="month"],
[type="search"],
[type="tel"],
[type="time"],
[type="week"],
[multiple],
textarea,
select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: #fff;
  border-color: #6e6e76;
  border-width: 1px;
  border-radius: 0px;
  padding-top: 0.5rem;
  padding-right: 0.75rem;
  padding-bottom: 0.5rem;
  padding-left: 0.75rem;
  font-size: 1rem;
  line-height: 1.5rem;
  --tw-shadow: 0 0 #0000;
}
[type="text"]:focus,
[type="email"]:focus,
[type="url"]:focus,
[type="password"]:focus,
[type="number"]:focus,
[type="date"]:focus,
[type="datetime-local"]:focus,
[type="month"]:focus,
[type="search"]:focus,
[type="tel"]:focus,
[type="time"]:focus,
[type="week"]:focus,
[multiple]:focus,
textarea:focus,
select:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
  --tw-ring-inset: var(--tw-empty, /*!*/ /*!*/);
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #2563eb;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
    var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
    calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
    var(--tw-shadow);
  border-color: #2563eb;
}
input::-moz-placeholder,
textarea::-moz-placeholder {
  color: #6e6e76;
  opacity: 1;
}
input:-ms-input-placeholder,
textarea:-ms-input-placeholder {
  color: #6e6e76;
  opacity: 1;
}
input::placeholder,
textarea::placeholder {
  color: #6e6e76;
  opacity: 1;
}
::-webkit-datetime-edit-fields-wrapper {
  padding: 0;
}
::-webkit-date-and-time-value {
  min-height: 1.5em;
}
.gordita-regular {
  font-family: Gordita Regular !important;
  width: 530px;
  height: 60px;
  flex-grow: 0;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: -0.5px;
  text-align: left;
  color: rgba(29, 38, 58, 0.8);
}
.btn-schedule {
  font-family: Gordita Regular !important;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.25px;
  text-align: center;
  color: #fff;
}
.header-text {
  flex-grow: 0;
  font-family: Gordita Regular !important;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: -0.2px;
  text-align: left;
  color: rgba(29, 38, 58, 0.8);
}
.ml-6 {
  margin-left: 5rem;
}
.btn-dental {
  font-family: Gordita Regular !important;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.25px;
  text-align: left;
  color: #fff;
}
img.call-1 {
  width: 16px;
  height: 16px;
  flex-grow: 0;
  object-fit: contain;
}
.font-playfair-display {
  width: 530px;
  height: 240px;
  flex-grow: 0;
  margin: 0 20px 16px 0;
  font-family: TiemposHeadline Medium !important;
  font-size: 60px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: -1px;
  text-align: left;
  color: #191919;
}
select {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3e%3cpath stroke='%236E6E76' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M6 8l4 4 4-4'/%3e%3c/svg%3e");
  background-position: right 0.5rem center;
  background-repeat: no-repeat;
  background-size: 1.5em 1.5em;
  padding-right: 2.5rem;
  -webkit-print-color-adjust: exact;
  print-color-adjust: exact;
}
[multiple] {
  background-image: initial;
  background-position: initial;
  background-repeat: unset;
  background-size: initial;
  padding-right: 0.75rem;
  -webkit-print-color-adjust: unset;
  print-color-adjust: unset;
}
[type="checkbox"],
[type="radio"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding: 0;
  -webkit-print-color-adjust: exact;
  print-color-adjust: exact;
  display: inline-block;
  vertical-align: middle;
  background-origin: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  flex-shrink: 0;
  height: 1rem;
  width: 1rem;
  color: #2563eb;
  background-color: #fff;
  border-color: #6e6e76;
  border-width: 1px;
  --tw-shadow: 0 0 #0000;
}
[type="checkbox"] {
  border-radius: 0px;
}
[type="radio"] {
  border-radius: 100%;
}
[type="checkbox"]:focus,
[type="radio"]:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
  --tw-ring-inset: var(--tw-empty, /*!*/ /*!*/);
  --tw-ring-offset-width: 2px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #2563eb;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
    var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
    calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
    var(--tw-shadow);
}
[type="checkbox"]:checked,
[type="radio"]:checked {
  border-color: transparent;
  background-color: currentColor;
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
}
[type="checkbox"]:checked {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z'/%3e%3c/svg%3e");
}
[type="radio"]:checked {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3ccircle cx='8' cy='8' r='3'/%3e%3c/svg%3e");
}
[type="checkbox"]:checked:hover,
[type="checkbox"]:checked:focus,
[type="radio"]:checked:hover,
[type="radio"]:checked:focus {
  border-color: transparent;
  background-color: currentColor;
}
[type="checkbox"]:indeterminate {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 16 16'%3e%3cpath stroke='white' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M4 8h8'/%3e%3c/svg%3e");
  border-color: transparent;
  background-color: currentColor;
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
}
[type="checkbox"]:indeterminate:hover,
[type="checkbox"]:indeterminate:focus {
  border-color: transparent;
  background-color: currentColor;
}
[type="file"] {
  background: unset;
  border-color: inherit;
  border-width: 0;
  border-radius: 0;
  padding: 0;
  font-size: unset;
  line-height: inherit;
}
[type="file"]:focus {
  outline: 1px auto -webkit-focus-ring-color;
}
*,
::before,
::after {
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgb(59 130 246 / 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}
.container {
  width: 100%;
}
@media (min-width: 640px) {
  .container {
    max-width: 640px;
  }
}
@media (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}
@media (min-width: 1024px) {
  .container {
    max-width: 1024px;
  }
}
@media (min-width: 1280px) {
  .container {
    max-width: 1280px;
  }
}
@media (min-width: 1536px) {
  .container {
    max-width: 1536px;
  }
}
.h1 {
  font-size: 3.5rem;
  line-height: 1.18;
  font-weight: 500;
  letter-spacing: -0.02em;
}
.h2 {
  flex-grow: 0;
  font-family: TiemposHeadline Medium;
  font-size: 56px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: -0.5px;
  color: #191919;
}
.h3 {
  font-size: 2.63rem;
  line-height: 1.24;
  font-weight: 500;
}
.h4 {
  font-size: 1.5rem;
  line-height: 1.35;
  font-weight: 500;
  letter-spacing: -0.01em;
}
@media (min-width: 768px) {
  .h1 {
    font-size: 60px;
    line-height: 1.25;
  }

  .h2 {
    font-size: 3.5rem;
    line-height: 1.18;
  }
}
.btn,
.btn-sm {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.25rem;
  border-width: 1px;
  border-color: transparent;
  font-weight: 500;
  line-height: 1.375;
  transition-property: color, background-color, border-color, fill, stroke,
    opacity, box-shadow, transform, filter, -webkit-text-decoration-color,
    -webkit-backdrop-filter;
  transition-property: color, background-color, border-color,
    text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter,
    backdrop-filter;
  transition-property: color, background-color, border-color,
    text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter,
    backdrop-filter, -webkit-text-decoration-color, -webkit-backdrop-filter;
  transition-duration: 150ms;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}
.btn {
  padding-left: 2rem;
  padding-right: 2rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.btn-sm {
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 1rem;
  border-radius: 4px;
  padding-bottom: 1rem;
}
.form-input,
.form-textarea,
.form-multiselect,
.form-select,
.form-checkbox,
.form-radio {
  border-width: 1px;
  --tw-border-opacity: 1;
  border-color: rgb(212 212 216 / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}
.form-input:focus,
.form-textarea:focus,
.form-multiselect:focus,
.form-select:focus,
.form-checkbox:focus,
.form-radio:focus {
  --tw-border-opacity: 1;
  border-color: rgb(162 162 168 / var(--tw-border-opacity));
}
.form-input,
.form-textarea,
.form-multiselect,
.form-select,
.form-checkbox {
  border-radius: 0.25rem;
}
.form-input,
.form-textarea,
.form-multiselect,
.form-select {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  padding-left: 1rem;
  padding-right: 1rem;
  line-height: 1.375;
}
.form-input::-moz-placeholder,
.form-textarea::-moz-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(110 110 118 / var(--tw-placeholder-opacity));
}
.form-input:-ms-input-placeholder,
.form-textarea:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(110 110 118 / var(--tw-placeholder-opacity));
}
.form-input::placeholder,
.form-textarea::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(110 110 118 / var(--tw-placeholder-opacity));
}
.form-select {
  padding-right: 2.5rem;
}
.form-checkbox,
.form-radio {
  --tw-text-opacity: 1;
  color: rgb(49 151 149 / var(--tw-text-opacity));
}
/* Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
  display: none;
}
.no-scrollbar {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}
.dark .h4 {
  font-weight: 700;
}
.dark .form-input,
.dark .form-textarea,
.dark .form-multiselect,
.dark .form-select,
.dark .form-checkbox,
.dark .form-radio {
  border-width: 1px;
  --tw-border-opacity: 1;
  border-color: rgb(82 82 90 / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgb(46 46 51 / var(--tw-bg-opacity));
}
.dark .form-input:focus,
.dark .form-textarea:focus,
.dark .form-multiselect:focus,
.dark .form-select:focus,
.dark .form-checkbox:focus,
.dark .form-radio:focus {
  --tw-border-opacity: 1;
  border-color: rgb(110 110 118 / var(--tw-border-opacity));
}
.dark .form-input::-moz-placeholder,
.dark .form-textarea::-moz-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(162 162 168 / var(--tw-placeholder-opacity));
}
.dark .form-input:-ms-input-placeholder,
.dark .form-textarea:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(162 162 168 / var(--tw-placeholder-opacity));
}
.dark .form-input::placeholder,
.dark .form-textarea::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(162 162 168 / var(--tw-placeholder-opacity));
}
input[type="range"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: #ccc;
  border-radius: 3px;
  height: 6px;
  margin-top: 15px;
  margin-bottom: 15px;
  --thumb-size: 36px;
}
input[type="range"]::-webkit-slider-thumb {
  appearance: none;
  -webkit-appearance: none;
  background-color: #000;
  background-image: url("data:image/svg+xml,%3Csvg width='12' height='8' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8 .5v7L12 4zM0 4l4 3.5v-7z' fill='%23FFF' fill-rule='nonzero'/%3E%3C/svg%3E");
  background-position: center;
  background-repeat: no-repeat;
  border: 0;
  border-radius: 50%;
  cursor: pointer;
  height: 36px;
  width: 36px;
}
input[type="range"]::-moz-range-thumb {
  background-color: #000;
  background-image: url("data:image/svg+xml,%3Csvg width='12' height='8' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8 .5v7L12 4zM0 4l4 3.5v-7z' fill='%23FFF' fill-rule='nonzero'/%3E%3C/svg%3E");
  background-position: center;
  background-repeat: no-repeat;
  border: 0;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  height: 36px;
  width: 36px;
}
input[type="range"]::-ms-thumb {
  background-color: #000;
  background-image: url("data:image/svg+xml,%3Csvg width='12' height='8' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8 .5v7L12 4zM0 4l4 3.5v-7z' fill='%23FFF' fill-rule='nonzero'/%3E%3C/svg%3E");
  background-position: center;
  background-repeat: no-repeat;
  border: 0;
  border-radius: 50%;
  cursor: pointer;
  height: 36px;
  width: 36px;
}
input[type="range"]::-moz-focus-outer {
  border: 0;
}
.form-switch {
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 44px;
}
.form-switch label {
  display: block;
  height: 1.5rem;
  cursor: pointer;
  overflow: hidden;
  border-radius: 9999px;
}
.form-switch label > span:first-child {
  position: absolute;
  display: block;
  border-radius: 9999px;
  width: 20px;
  height: 20px;
  top: 2px;
  left: 2px;
  right: 50%;
  transition: all 0.15s ease-out;
}
.form-switch input[type="checkbox"]:checked + label {
  --tw-bg-opacity: 1;
  background-color: rgb(58 186 180 / var(--tw-bg-opacity));
}
.form-switch input[type="checkbox"]:checked + label > span:first-child {
  left: 22px;
}
.form-input:focus,
.form-textarea:focus,
.form-multiselect:focus,
.form-select:focus,
.form-checkbox:focus,
.form-radio:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
    var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
    calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
    var(--tw-shadow, 0 0 #0000);
}
.hamburger svg > *:nth-child(1),
.hamburger svg > *:nth-child(2),
.hamburger svg > *:nth-child(3) {
  transform-origin: center;
  transform: rotate(0deg);
}

.hamburger.active svg > *:nth-child(2) {
  transform: rotate(45deg);
  transition-delay: 0.12s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}
.form-switch input[type="checkbox"].light-switch + label {
  --tw-bg-opacity: 1;
  background-color: rgb(58 186 180 / var(--tw-bg-opacity));
}
.dark
  .form-switch
  input[type="checkbox"].light-switch
  + label
  > span:first-child {
  left: 22px;
}
.carousel.swiper-container {
  overflow: visible;
}
.carousel .swiper-slide img {
  opacity: 0.32;
}
.carousel .swiper-slide > div > div {
  opacity: 0;
}
.carousel .swiper-slide.swiper-slide-active,
.carousel .swiper-slide.swiper-slide-duplicate-active {
  --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.08);
  --tw-shadow-colored: 0 25px 50px -12px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.carousel .swiper-slide.swiper-slide-active img,
.carousel .swiper-slide.swiper-slide-duplicate-active img {
  opacity: 1;
}
.carousel .swiper-slide.swiper-slide-active > div > div,
.carousel .swiper-slide.swiper-slide-duplicate-active > div > div {
  opacity: 1;
}
.translate-z-0 {
  transform: translateZ(0);
}
.tags-animation {
  -webkit-animation: tagsmove 30000ms linear infinite;
  animation: tagsmove 30000ms linear infinite;
}
.tags-1 {
  -webkit-animation-delay: -3000ms;
  animation-delay: -3000ms;
}
.tags-2 {
  -webkit-animation-delay: -4800ms;
  animation-delay: -4800ms;
}
.tags-3 {
  -webkit-animation-delay: -6900ms;
  animation-delay: -6900ms;
}
.tags-4 {
  -webkit-animation-delay: -8100ms;
  animation-delay: -8100ms;
}
.tags-5 {
  -webkit-animation-delay: -13200ms;
  animation-delay: -13200ms;
}
.tags-6 {
  -webkit-animation-delay: -16500ms;
  animation-delay: -16500ms;
}
.tags-7 {
  -webkit-animation-delay: -18900ms;
  animation-delay: -18900ms;
}
.tags-8 {
  -webkit-animation-delay: -21900ms;
  animation-delay: -21900ms;
}
.tags-9 {
  -webkit-animation-delay: -22800ms;
  animation-delay: -22800ms;
}
.tags-10 {
  -webkit-animation-delay: -24000ms;
  animation-delay: -24000ms;
}
@-webkit-keyframes tagsmove {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(768px);
  }
}
@keyframes tagsmove {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(768px);
  }
}
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}
.pointer-events-none {
  pointer-events: none;
}
.fixed {
  position: fixed;
}
.absolute {
  position: absolute;
}
.relative {
  position: relative;
}
.inset-0 {
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  clip-path: polygon(0 0, 5760px 0, 5760px calc(100%-352px), 0 100%);
}
.left-full {
  left: 100%;
}
.bottom-0 {
  bottom: 0px;
}
.left-0 {
  left: 0px;
}
.right-0 {
  right: 0px;
}
.left-auto {
  left: auto;
}
.top-4 {
  top: 1rem;
}
.top-0 {
  top: 0px;
}
.right-auto {
  right: auto;
}
.left-1\/2 {
  left: 50%;
}
.top-full {
  top: 100%;
}
.-z-1 {
  z-index: -1;
}
.z-20 {
  z-index: 20;
}
.z-30 {
  z-index: 30;
}
.z-10 {
  z-index: 10;
}
.-z-10 {
  z-index: -10;
}
.z-50 {
  z-index: 50;
}
.order-first {
  order: -9999;
}
.col-span-4 {
  grid-column: span 4 / span 4;
}
.col-span-3 {
  grid-column: span 3 / span 3;
}
.col-span-5 {
  grid-column: span 5 / span 5;
}
.m-4 {
  margin: 1rem;
}
.-m-1 {
  margin: -0.25rem;
}
.m-1 {
  margin: 0.25rem;
}
.-m-px {
  margin: -1px;
}
.-m-5 {
  margin: -1.25rem;
}
.mx-auto {
  margin-left: auto;
  margin-right: auto;
}
.-mx-3 {
  margin-left: -0.75rem;
  margin-right: -0.75rem;
}
.-my-3 {
  margin-top: -0.75rem;
  margin-bottom: -0.75rem;
}
.-my-1 {
  margin-top: -0.25rem;
  margin-bottom: -0.25rem;
}
.my-2 {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
.mx-1 {
  margin-left: 0.25rem;
  margin-right: 0.25rem;
}
.mx-4 {
  margin-left: 1rem;
  margin-right: 1rem;
}
.my-6 {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}
.mb-4 {
  margin-bottom: 1rem;
}
.mb-5 {
  margin-bottom: 1.25rem;
}
.mb-1 {
  margin-bottom: 0.25rem;
}
.mb-3 {
  margin-bottom: 0.75rem;
}
.mb-2 {
  margin-bottom: 0.5rem;
}
.ml-3 {
  margin-left: 0.75rem;
}
.mt-6 {
  margin-top: 1.5rem;
}
.mt-px {
  margin-top: 1px;
}
.ml-2 {
  margin-left: 0.5rem;
}
.mb-8 {
  margin-bottom: 2rem;
}
.mt-4 {
  margin-top: 1rem;
}
.mr-3 {
  margin-right: 0.75rem;
}
.mt-12 {
  margin-top: 3rem;
}
.mt-1 {
  margin-top: 0.25rem;
}
.mt-5 {
  margin-top: 1.25rem;
}
.mt-3 {
  margin-top: 0.75rem;
}
.mt-2 {
  margin-top: 0.5rem;
}
.mb-6 {
  margin-bottom: 1.5rem;
}
.-mt-3 {
  margin-top: -0.75rem;
}
.mt-8 {
  margin-top: 2rem;
}
.mt-28 {
  margin-top: 7rem;
}
.mt-72 {
  margin-top: 18rem;
}
.mt-40 {
  margin-top: 10rem;
}
.mt-80 {
  margin-top: 20rem;
}
.mt-56 {
  margin-top: 14rem;
}
.-mt-px {
  margin-top: -1px;
}
.ml-24 {
  margin-left: 6rem;
}
.ml-4 {
  margin-left: 1rem;
}
.mr-4 {
  margin-right: 1rem;
}
.mr-5 {
  margin-right: 1.25rem;
}
.ml-6 {
  margin-left: 1.5rem;
}
.mr-6 {
  margin-right: 1.5rem;
}
.-mt-0\.5 {
  margin-top: -0.125rem;
}
.-mt-0 {
  margin-top: -0px;
}
.-ml-16 {
  margin-left: -4rem;
}
.mb-10 {
  margin-bottom: 2.5rem;
}
.mr-2 {
  margin-right: 0.5rem;
}
.-mb-2 {
  margin-bottom: -0.5rem;
}
.-ml-2 {
  margin-left: -0.5rem;
}
.-mb-8 {
  margin-bottom: -2rem;
}
.mr-12 {
  margin-right: 3rem;
}
.mt-32 {
  margin-top: 8rem;
}
.-mr-16 {
  margin-right: -4rem;
}
.mt-24 {
  margin-top: 6rem;
}
.mt-20 {
  margin-top: 5rem;
}
.-mr-8 {
  margin-right: -2rem;
}
.-mt-6 {
  margin-top: -1.5rem;
}
.ml-16 {
  margin-left: 4rem;
}
.ml-1 {
  margin-left: 0.25rem;
}
.box-content {
  box-sizing: content-box;
}
.block {
  display: block;
}
.inline-block {
  display: inline-block;
}
.flex {
  display: flex;
}
.inline-flex {
  display: inline-flex;
}
.table {
  display: table;
}
.grid {
  display: grid;
}
.hidden {
  display: none;
}
.h-0 {
  height: 0px;
}
.h-3 {
  height: 0.75rem;
}
.h-full {
  height: 100%;
}
.h-4 {
  height: 1rem;
}
.h-128 {
  height: 32rem;
}
.h-px {
  height: 1px;
}
.h-12 {
  height: 3rem;
}
.h-24 {
  height: 6rem;
}
.h-8 {
  height: 2rem;
}
.h-6 {
  height: 1.5rem;
}
.h-0\.5 {
  height: 0.125rem;
}
.h-20 {
  height: 5rem;
}
.h-screen {
  height: 100vh;
}
.h-16 {
  height: 4rem;
}
.h-auto {
  height: auto;
}
.h-3\.5 {
  height: 0.875rem;
}
.max-h-full {
  max-height: 100%;
}
.min-h-screen {
  min-height: 100vh;
}
.w-full {
  width: 100%;
}
.w-3 {
  width: 0.75rem;
}
.w-4 {
  width: 1rem;
}
.w-12 {
  width: 3rem;
}
.w-5 {
  width: 1.25rem;
}
.w-20 {
  width: 5rem;
}
.w-8 {
  width: 2rem;
}
.w-6 {
  width: 1.5rem;
}
.w-0\.5 {
  width: 0.125rem;
}
.w-0 {
  width: 0px;
}
.w-1\/2 {
  width: 50%;
}
.w-3\.5 {
  width: 0.875rem;
}
.w-16 {
  width: 4rem;
}
.w-40 {
  width: 10rem;
}
.max-w-6xl {
  max-width: 72rem;
}
.max-w-3xl {
  max-width: 48rem;
}
.max-w-xl {
  max-width: 36rem;
}
.max-w-xs {
  max-width: 20rem;
}
.max-w-lg {
  max-width: 32rem;
}
.max-w-full {
  max-width: 100%;
}
.max-w-sm {
  max-width: 24rem;
}
.max-w-2xl {
  max-width: 42rem;
}
.max-w-5xl {
  max-width: 64rem;
}
.shrink-0 {
  flex-shrink: 0;
}
.grow {
  flex-grow: 1;
}
.origin-top-right {
  transform-origin: top right;
}
.-translate-x-2 {
  --tw-translate-x: -0.5rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.-translate-x-1\/2 {
  --tw-translate-x: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.-translate-x-full {
  --tw-translate-x: -100%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.translate-x-0 {
  --tw-translate-x: 0px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.translate-x-1\/2 {
  --tw-translate-x: 50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.-translate-y-8 {
  --tw-translate-y: -2rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.translate-y-0 {
  --tw-translate-y: 0px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.translate-y-8 {
  --tw-translate-y: 2rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.translate-x-2 {
  --tw-translate-x: 0.5rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.-translate-y-2 {
  --tw-translate-y: -0.5rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.-skew-x-3 {
  --tw-skew-x: -3deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.scale-95 {
  --tw-scale-x: 0.95;
  --tw-scale-y: 0.95;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.scale-100 {
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.transform {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
@-webkit-keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
.animate-spin {
  -webkit-animation: spin 1s linear infinite;
  animation: spin 1s linear infinite;
}
@-webkit-keyframes float {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10%);
  }
}
@keyframes float {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10%);
  }
}
.animate-float {
  -webkit-animation: float 5s ease-in-out infinite;
  animation: float 5s ease-in-out infinite;
}
.cursor-pointer {
  cursor: pointer;
}
.list-inside {
  list-style-position: inside;
}
.list-decimal {
  list-style-type: decimal;
}
.grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}
.grid-cols-12 {
  grid-template-columns: repeat(12, minmax(0, 1fr));
}
.flex-col {
  flex-direction: column;
}
.flex-wrap {
  flex-wrap: wrap;
}
.items-start {
  align-items: flex-start;
}
.items-center {
  align-items: center;
}
.items-baseline {
  align-items: baseline;
}
.justify-end {
  justify-content: flex-end;
}
.justify-center {
  justify-content: center;
}
.justify-between {
  justify-content: space-between;
}
.gap-12 {
  gap: 3rem;
}
.gap-4 {
  gap: 1rem;
}
.gap-2 {
  gap: 0.5rem;
}
.gap-3 {
  gap: 0.75rem;
}
.gap-8 {
  gap: 2rem;
}
.gap-6 {
  gap: 1.5rem;
}
.gap-y-8 {
  row-gap: 2rem;
}
.gap-x-8 {
  -moz-column-gap: 2rem;
  column-gap: 2rem;
}
.gap-y-12 {
  row-gap: 3rem;
}
.divide-x > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-x-reverse: 0;
  border-right-width: calc(1px * var(--tw-divide-x-reverse));
  border-left-width: calc(1px * calc(1 - var(--tw-divide-x-reverse)));
}
.divide-gray-200 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgb(228 228 231 / var(--tw-divide-opacity));
}
.self-end {
  align-self: flex-end;
}
.self-center {
  align-self: center;
}
.overflow-auto {
  overflow: auto;
}
.overflow-hidden {
  overflow: hidden;
}
.overflow-scroll {
  overflow: scroll;
}
.truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.break-words {
  overflow-wrap: break-word;
}
.rounded-full {
  border-radius: 9999px;
}
.rounded {
  border-radius: 0.25rem;
}
.border-0 {
  border-width: 0px;
}
.border {
  border-width: 1px;
}
.border-2 {
  border-width: 2px;
}
.border-b {
  border-bottom-width: 1px;
}
.border-l-2 {
  border-left-width: 2px;
}
.border-t {
  border-top-width: 1px;
}
.border-dashed {
  border-style: dashed;
}
.border-gray-200 {
  --tw-border-opacity: 1;
  border-color: rgb(228 228 231 / var(--tw-border-opacity));
}
.border-teal-500 {
  --tw-border-opacity: 1;
  border-color: rgb(58 186 180 / var(--tw-border-opacity));
}
.border-transparent {
  border-color: transparent;
}
.border-white {
  --tw-border-opacity: 1;
  border-color: rgb(255 255 255 / var(--tw-border-opacity));
}
.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}
.bg-teal-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(58 186 180 / var(--tw-bg-opacity));
}
.bg-gray-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(46 46 51 / var(--tw-bg-opacity));
}
.bg-gray-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(162 162 168 / var(--tw-bg-opacity));
}
.bg-gray-900 {
  --tw-bg-opacity: 1;
  background-color: rgb(29 29 32 / var(--tw-bg-opacity));
}
.bg-purple-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(159 122 234 / var(--tw-bg-opacity));
}
.bg-indigo-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(102 126 234 / var(--tw-bg-opacity));
}
.bg-pink-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(237 100 166 / var(--tw-bg-opacity));
}
.bg-gray-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(212 212 216 / var(--tw-bg-opacity));
}
.bg-teal-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(178 245 234 / var(--tw-bg-opacity));
}
.bg-teal-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(79 209 197 / var(--tw-bg-opacity));
}
.bg-black {
  --tw-bg-opacity: 1;
  background-color: rgb(0 0 0 / var(--tw-bg-opacity));
}
.bg-opacity-50 {
  --tw-bg-opacity: 0.5;
}
.bg-opacity-75 {
  --tw-bg-opacity: 0.75;
}
.bg-gradient-to-t {
  background-image: linear-gradient(to top, var(--tw-gradient-stops));
}
.bg-gradient-to-b {
  background-image: linear-gradient(to bottom, var(--tw-gradient-stops));
}
.bg-gradient-to-tr {
  background-image: linear-gradient(to top right, var(--tw-gradient-stops));
}
.bg-gradient-to-r {
  background-image: linear-gradient(to right, var(--tw-gradient-stops));
}
.bg-gradient-to-l {
  background-image: linear-gradient(to left, var(--tw-gradient-stops));
}
.from-white {
  --tw-gradient-from: #fff;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgb(255 255 255 / 0));
}
.from-gray-100 {
  --tw-gradient-from: #f4f4f5;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgb(244 244 245 / 0));
}
.from-gray-900 {
  --tw-gradient-from: #1d1d20;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgb(29 29 32 / 0));
}
.from-indigo-500 {
  --tw-gradient-from: #667eea;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgb(102 126 234 / 0));
}
.from-teal-500 {
  --tw-gradient-from: #3abab4;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgb(58 186 180 / 0));
}
.from-pink-500 {
  --tw-gradient-from: #ed64a6;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgb(237 100 166 / 0));
}
.from-purple-500 {
  --tw-gradient-from: #9f7aea;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgb(159 122 234 / 0));
}
.from-gray-800 {
  --tw-gradient-from: #2e2e33;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgb(46 46 51 / 0));
}
.via-gray-300 {
  --tw-gradient-stops: var(--tw-gradient-from), #d4d4d8,
    var(--tw-gradient-to, rgb(212 212 216 / 0));
}
.via-teal-400 {
  --tw-gradient-stops: var(--tw-gradient-from), #4fd1c5,
    var(--tw-gradient-to, rgb(79 209 197 / 0));
}
.to-white {
  --tw-gradient-to: #fff;
}
.to-indigo-400 {
  --tw-gradient-to: #7f9cf5;
}
.to-teal-400 {
  --tw-gradient-to: #4fd1c5;
}
.to-pink-400 {
  --tw-gradient-to: #f687b3;
}
.to-purple-400 {
  --tw-gradient-to: #b794f4;
}
.to-gray-900 {
  --tw-gradient-to: #1d1d20;
}
.fill-current {
  fill: currentColor;
}
.object-cover {
  -o-object-fit: cover;
  object-fit: cover;
}
.p-6 {
  padding: 1.5rem;
}
.p-1 {
  padding: 0.25rem;
}
.p-2 {
  padding: 0.5rem;
}
.p-5 {
  padding: 1.25rem;
}
.p-3 {
  padding: 0.75rem;
}
.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}
.px-3 {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}
.py-3 {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}
.py-8 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}
.px-8 {
  padding-left: 2rem;
  padding-right: 2rem;
}
.py-2 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.py-12 {
  padding-top: 3rem;
  padding-bottom: 3rem;
}
.py-1 {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}
.py-10 {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}
.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}
.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.px-5 {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}
.py-6 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}
.py-5 {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}
.px-1 {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}
.px-12 {
  padding-left: 3rem;
  padding-right: 3rem;
}
.pt-32 {
  padding-top: 8rem;
}
.pb-12 {
  padding-bottom: 3rem;
}
.pb-9\/16 {
  padding-bottom: 56.25%;
}
.pt-16 {
  padding-top: 4rem;
}
.pt-px {
  padding-top: 1px;
}
.pl-4 {
  padding-left: 1rem;
}
.pt-6 {
  padding-top: 1.5rem;
}
.pr-4 {
  padding-right: 1rem;
}
.pl-20 {
  padding-left: 5rem;
}
.pr-6 {
  padding-right: 1.5rem;
}
.pl-6 {
  padding-left: 1.5rem;
}
.pl-12 {
  padding-left: 3rem;
}
.pb-10 {
  padding-bottom: 2.5rem;
}
.pb-4 {
  padding-bottom: 1rem;
}
.pr-2 {
  padding-right: 0.5rem;
}
.text-left {
  text-align: left;
}
.text-center {
  text-align: center;
}
.text-right {
  text-align: right;
}
.font-inter {
  font-family: Inter, sans-serif;
}
.font-red-hat-display {
  font-family: "Red Hat Display", sans-serif;
}
.text-xl {
  font-size: 1.25rem;
  line-height: 1.5;
}
.text-sm {
  font-size: 0.875rem;
  line-height: 1.5;
}
.text-lg {
  font-size: 1rem;
  line-height: 1.5;
}
.text-xs {
  font-size: 0.75rem;
  line-height: 1.5;
}
.text-3xl {
  font-size: 2.63rem;
  line-height: 1.24;
}
.font-medium {
  font-weight: 500;
}
.font-bold {
  font-weight: 700;
}
.font-extrabold {
  font-weight: 800;
}
.uppercase {
  text-transform: uppercase;
}
.italic {
  font-style: italic;
}
.not-italic {
  font-style: normal;
}
.leading-tight {
  line-height: 1.25;
}
.tracking-tight {
  letter-spacing: -0.01em;
}
.tracking-tighter {
  letter-spacing: -0.02em;
}
.text-gray-900 {
  --tw-text-opacity: 1;
  color: rgb(29 29 32 / var(--tw-text-opacity));
}
.text-gray-600 {
  --tw-text-opacity: 1;
  color: rgb(82 82 90 / var(--tw-text-opacity));
}
.text-gray-800 {
  --tw-text-opacity: 1;
  color: rgb(46 46 51 / var(--tw-text-opacity));
}
.text-red-600 {
  --tw-text-opacity: 1;
  color: rgb(220 38 38 / var(--tw-text-opacity));
}
.text-gray-500 {
  --tw-text-opacity: 1;
  color: rgb(110 110 118 / var(--tw-text-opacity));
}
.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}
.text-gray-300 {
  --tw-text-opacity: 1;
  color: rgb(212 212 216 / var(--tw-text-opacity));
}
.text-gray-400 {
  --tw-text-opacity: 1;
  color: rgb(162 162 168 / var(--tw-text-opacity));
}
.text-teal-500 {
  --tw-text-opacity: 1;
  color: rgb(58 186 180 / var(--tw-text-opacity));
}
.text-gray-100 {
  --tw-text-opacity: 1;
  color: rgb(244 244 245 / var(--tw-text-opacity));
}
.text-purple-500 {
  --tw-text-opacity: 1;
  color: rgb(159 122 234 / var(--tw-text-opacity));
}
.text-indigo-500 {
  --tw-text-opacity: 1;
  color: rgb(102 126 234 / var(--tw-text-opacity));
}
.text-pink-500 {
  --tw-text-opacity: 1;
  color: rgb(237 100 166 / var(--tw-text-opacity));
}
.text-gray-700 {
  --tw-text-opacity: 1;
  color: rgb(63 63 69 / var(--tw-text-opacity));
}
.text-teal-200 {
  --tw-text-opacity: 1;
  color: rgb(178 245 234 / var(--tw-text-opacity));
}
.text-purple-200 {
  --tw-text-opacity: 1;
  color: rgb(233 216 253 / var(--tw-text-opacity));
}
.text-indigo-200 {
  --tw-text-opacity: 1;
  color: rgb(195 218 254 / var(--tw-text-opacity));
}
.text-pink-200 {
  --tw-text-opacity: 1;
  color: rgb(254 215 226 / var(--tw-text-opacity));
}
.text-teal-100 {
  --tw-text-opacity: 1;
  color: rgb(230 255 250 / var(--tw-text-opacity));
}
.text-purple-100 {
  --tw-text-opacity: 1;
  color: rgb(243 232 255 / var(--tw-text-opacity));
}
.text-indigo-100 {
  --tw-text-opacity: 1;
  color: rgb(235 244 255 / var(--tw-text-opacity));
}
.text-pink-100 {
  --tw-text-opacity: 1;
  color: rgb(255 235 239 / var(--tw-text-opacity));
}
.text-teal-400 {
  --tw-text-opacity: 1;
  color: rgb(79 209 197 / var(--tw-text-opacity));
}
.text-teal-600 {
  --tw-text-opacity: 1;
  color: rgb(49 151 149 / var(--tw-text-opacity));
}
.text-gray-200 {
  --tw-text-opacity: 1;
  color: rgb(228 228 231 / var(--tw-text-opacity));
}
.underline {
  -webkit-text-decoration-line: underline;
  text-decoration-line: underline;
}
.antialiased {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.opacity-50 {
  opacity: 0.5;
}
.opacity-25 {
  opacity: 0.25;
}
.opacity-80 {
  opacity: 0.8;
}
.opacity-75 {
  opacity: 0.75;
}
.opacity-70 {
  opacity: 0.7;
}
.opacity-30 {
  opacity: 0.3;
}
.opacity-0 {
  opacity: 0;
}
.opacity-100 {
  opacity: 1;
}
.opacity-90 {
  opacity: 0.9;
}
.opacity-10 {
  opacity: 0.1;
}
.text-slate-100 {
  --tw-text-opacity: 1;
  color: rgb(241 245 249 / var(--tw-text-opacity));
}
.font-playfair-display {
  font-family: Playfair Display, serif;
}
.\[clip-path\:polygon\(0_0\2c
  _5760px_0\2c
  _5760px_calc\(100\%_-_352px\)\2c
  _0_100\%\)\] {
  -webkit-clip-path: polygon(0 0, 5760px 0, 5760px calc(100% - 352px), 0 100%);
  clip-path: polygon(0 0, 5760px 0, 5760px calc(100% - 352px), 0 100%);
}
.bg-slate-900 {
  --tw-bg-opacity: 1;
  background-color: rgb(33 202 234 / var(--tw-bg-opacity));
}
.-z-10 {
  z-index: -10;
}
.footer-10 {
  background: rgb(5 36 65);
  padding: 0 0 4em 0;
}
.ftco-footer-social li {
  list-style: none;
  margin: 0 10px 0 0;
  display: inline-block;
}
.advacne {
  position: "absolute";
  width: 117.8%;
  max-width: 117.8%;
  top: -9.9%;
  left: -5.87%;
}
/* .illustration-element-03::after {
	illustration('illustration-element-03.svg', 200%, 200%);
} */
.inset-0 {
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  clip-path: polygon(0% 0%, 100% 0%, 100% 88%, 0% 100%);
}
.h-80 {
  height: 30rem;
}
.card-price {
  border: none !important;
  border-radius: 1rem !important;
}
.v-center {
  padding-top: 35vh !important;
}
.price-text {
  font-size: 40px;
  font-family: Gordita Regular !important;
  font-weight: 700;
}
.height-100 {
  height: 100vh !important;
}
.text-404 {
  font-size: 10rem;
  color: rgb(0, 139, 252);
}
.reveal-from-bottom {
  margin: 20px;
}
.clients {
  display: flex;
}
.min-w-30 {
  min-width: 38rem;
}
.section {
  margin-top: 2rem;
  margin-bottom: 2rem;
}
.bg-color {
  background-color: rgb(236, 250, 255);
}
.affordable {
  flex-grow: 0;
  background-image: linear-gradient(
    101deg,
    #0181f6 21%,
    #35b0ff 68%,
    #82ddfc 104%
  );
  font-family: TiemposHeadline Medium !important;
  font-size: 60px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: -1px;
  text-align: left;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
img.Killen-Logo {
  width: 250px;
  height: 40px;
  flex-grow: 0;
  object-fit: contain;
}
.h-20px {
  height: 20px;
}
.call-icon {
  display: flex;
  align-items: center;
}
.hover-blue:hover {
  color: #2563eb;
}
.w-33percent {
  width: 33%;
}
.bg-blue {
  background-color: #2563eb;
}
.h-35rem {
  height: 45rem;
}
.background-image {
  background-image: url("../images/illustration-element-03.svg");
}
.text-slate-400 {
  --tw-text-opacity: 1;
  color: rgb(148 163 184 / var(--tw-text-opacity));
}
.text-xl {
  font-size: 1.25rem;
  line-height: 1.5;
  letter-spacing: -0.01em;
}
.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}
.bg-blue-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(33 116 234 / var(--tw-bg-opacity));
}
.bg-blue-600:hover {
  background-color: #0181f6;
}
.bg-slate-700:hover {
  background-color: #152236;
}
.pop-h {
  font-size: 25px !important;
  margin-bottom: 1rem;
}
.close-btn {
  width: 60px !important;
  cursor: pointer;
  margin-left: auto !important;
}
.bg-slate-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(51 65 85 / var(--tw-bg-opacity));
}
.shadow-xl {
  --tw-shadow: 0 20px 25px -5px rgb(0 0 0 / 0.1),
    0 8px 10px -6px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 20px 25px -5px var(--tw-shadow-color),
    0 8px 10px -6px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.shadow-2xl {
  --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.08);
  --tw-shadow-colored: 0 25px 50px -12px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.shadow-sm {
  --tw-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.shadow-lg {
  --tw-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1),
    0 4px 6px -4px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color),
    0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.shadow {
  --tw-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color),
    0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.outline {
  outline-style: solid;
}
.filter {
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast)
    var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate)
    var(--tw-sepia) var(--tw-drop-shadow);
}
.transition-opacity {
  transition-property: opacity;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}
.transition {
  transition-property: color, background-color, border-color, fill, stroke,
    opacity, box-shadow, transform, filter, -webkit-text-decoration-color,
    -webkit-backdrop-filter;
  transition-property: color, background-color, border-color,
    text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter,
    backdrop-filter;
  transition-property: color, background-color, border-color,
    text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter,
    backdrop-filter, -webkit-text-decoration-color, -webkit-backdrop-filter;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}
.transition-all {
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}
.duration-300 {
  transition-duration: 300ms;
}
.duration-150 {
  transition-duration: 150ms;
}
.duration-700 {
  transition-duration: 700ms;
}
.duration-200 {
  transition-duration: 200ms;
}
.duration-100 {
  transition-duration: 100ms;
}
.ease-in-out {
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}
.ease-out {
  transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
}
